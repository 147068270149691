.miramosLogo {
    position: fixed;
    left: 4px;
}

.homeText {
    color: #FFFFFF;
    font-size: 32px;

    position: fixed;
    top: 8px;
    left: calc( 280px + 32px );
}

.facilityNameBlock {
    position: fixed;
    top: calc( 64px/2 - 40px/2 );
    height: 40px;
    /*right: calc( 32px + 220px + 32px);*/
    right: calc( 32px + 220px + 100px);

    .facilityIcon {
        position: absolute;
        fill: #FFF;
        top: calc( 40px/2 - 24px/2 );
        left: 0px;
    }

    .facilityName {
        display: inline;
        color: #FFFFFF;
        font-size: 20px;
    
        position: relative;
        top: calc( 40px/2 - 24px/2 );
        left: 30px;
    }
}

.usernameHoverBlock {
    position: fixed;
    top: calc( 64px/2 - 40px/2 );
    height: 40px;
    width: 220px;
    /*right: 32px;*/
    right: 100px;

    &:hover {
        background-color: grey;
        border-radius: 5px;
    }

    .userCircleIcon {
        position: absolute;
        fill:#FFF;
        top: calc( 40px/2 - 24px/2 );
        left: calc( 1696px - 1680px );     
    }

    .userNameText {
        position: absolute;
        width: 140px;
        color: #FFF;
        font-size: 20px;
        top: calc( 40px/2 - 24px/2 );
        left: calc( 1728px - 1680px );

        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
    }

    .angleIcon {
        position: absolute;
        top: calc( 40px/2 - 24px/2 );
        left: calc( 24px + 10px + 160px );        
    }
}

.logoutBlock {
    position: fixed;
    top: 50px;
    /*right: 32px;*/
    right: 100px;

    background-color: #FFFFFF;
    box-shadow: 0px 3px 6px #00000029;
    border-radius: 0px 0px 5px 5px;
    width: 220px;
    height: 104px;

    .logoutTextBlock {
        height: 45px;
        margin-top: 20px; 

        &:hover {
            background-color: grey;
        }
    }

    .logoutText {
        position: absolute;
        font-size: 20px;
        top: 30px;
        margin-left: 70px;
    }
}

.helpBlock {
    display: flex;
    position: fixed;
    height: 40px;

    top: calc(64px/2 - 40px/2);
    right: 0px;

    .helpIcon {
        fill: #FFF;
        padding-top: calc( 40px/2 - 24px/2 );
    }

    .helpText {
        color: #FFFFFF;
        cursor: pointer;
        font-size: 20px;
        text-decoration: none;

        padding-top: calc( 40px/2 - 24px/2 );
        padding-right: 15px;
    }
}