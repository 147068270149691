.login {
    --amplify-components-authenticator-router-box-shadow: #EFF0F2;
    --amplify-components-authenticator-router-border-style: #EFF0F2;
    --amplify-components-button-primary-background-color: #0062C2;
    --amplify-components-authenticator-container-width-max: 320px;
    --amplify-components-authenticator-form-padding: 0px;
}

.login__text {
    font-size: 20px;
    text-align: center;
    color: #333333;
    padding-top: 30px;
    padding-bottom: 16px;
}

.login__miramosLogo {
    display: block;
    margin-top: 100px;
    margin-left: 50px;    
}

.login__kmLogo {
    position: fixed;
    padding-top: 32px;
    left: calc( 50% - 150px );
}

.login__resetPasswordButtonWrapper {
  text-align: right;
}

.login__resetPasswordButton {
}
