.helpCard {
    display: block;

    background-color: #FFFFFF;
    border-radius: 10px;
    box-shadow: 0px 3px 6px #00000029;
    cursor: pointer;
    opacity: 1;
    padding-top: 40px;
    padding-left: 40px;
    padding-right: 40px;
    padding-bottom: 40px;

    overflow-y: scroll;
    max-height: 80vh;
}

.faqPosition {
    position: fixed;
    top: calc( 64px + 64px );
    left: calc( 55px + 30px );
    width: 900px;
}

.faq__contentPosition {
    margin-top: 20px;
    margin-left: 30px; 
}

.faq__breadPosition {
    margin-top: 20px;
    margin-left: 30px;
}

.videolinkPosition {
    position: fixed;
    top: calc( 64px + 64px + 300px );
    left: calc( 55px + 30px + 900px + 60px );
    width: 700px;
    line-height: 50px;
}

.manualdownloadPosition {
    position: fixed;
    top: calc( 64px + 64px );
    left: calc( 55px + 30px + 900px + 60px );
    width: 700px;
    line-height: 50px;
}

.faq__markdown {
    font-size: 20px;
}

.faq__accordionButton {
    background-color: #e7f1ff;
    border: none;
    font-size: 20px !important;
}