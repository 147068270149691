.sidenav {
    background-color: #333941;
    height: 100%;
    width: 56px;

    &:hover {
        width: 280px;
    }
}

.icons {
    padding-left: calc((56px - 24px) / 2);

    .icon {
        padding-bottom: calc((56px - 24px) / 2);
        padding-top: calc((56px - 24px) / 2);
    }
    .envelopIcon {
        position: fixed;
        bottom: calc(15px + 24px + 15px);        
    }
    .arrowIconRight {
        position: fixed;
        bottom: 15px;
    }
}

.line {
    background-color: #BBC2CC;
}

.openedSideNavHoverBlock {
    display: flex;
    height: 56px;
    width: 280px;

    &:hover {
        background-color: #464E59;        
    }
    .openedIcon {
        padding-left: calc((56px - 24px) / 2);
        padding-bottom: calc((56px - 24px) / 2);
        padding-top: calc((56px - 24px) / 2);    
    }
    .openedText {
        font-size: 24px;
        height: 24px;
        margin-left: calc((56px - 24px) / 2);
        padding-bottom: calc((56px - 26px) / 2);
        padding-top: calc((56px - 26px) / 2);  
        color: #BBC2CC;   
        cursor: pointer;     
    }
}

.openedArrowIconLeft {
    position: fixed;
    bottom: 15px;
    left: calc(280px - 24px - 15px);
}

.openedInquiryHoverBlock{
    display: flex;
    position: fixed;
    height: 56px;
    width: 280px;
    bottom: calc(15px + 24px);

    &:hover {
        background-color: #464E59;        
    }
    .openedEnvelop {
        position: fixed;
        padding-left: calc((56px - 24px) / 2);
        bottom: calc(15px + 24px + 15px);
    }
    .openedInquiryText {
        font-size: 24px;
        color: #BBC2CC;
        text-decoration: none;
        cursor: pointer;
    
        position: fixed;
        padding-left: calc((56px - 24px) / 2 + 24px + 15px);
        bottom: calc(15px + 24px + 10px);
    }
}