.linkboxSquare {
    display: block;
    height: 244px;
    width: 328px;

    background-color: #FFFFFF;
    border-radius: 20px;
    box-shadow: 0px 3px 6px #00000029;
    cursor: pointer;
    opacity: 1;
    padding-top: 64px;

    .textUnderIcon {
        font-size: 20px;
        text-align: center;
        margin-top: 16px;
    }

    .circle {
        display: block;
        height: 80px;
        width: 80px;
    
        border-radius: 50%;
        margin-left: auto;
        margin-right: auto;
        opacity: 1;
        padding-top: calc( 80px/2 - 40px/2 );
        padding-left: calc( 80px/2 - 40px/2 );
    }
}