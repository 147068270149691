.app {
    display: grid;
    /* 1st row(header) is 64px. 2nd row is 100% of display size */
    grid-template-rows: 64px calc(100vh - 64px);
    grid-template-columns: 56px calc(100% - 56px);
}

/* 横幅が 1100px 以下であれば */
@media (max-width: 1100px){
    .app {
        grid-template-columns: 56px calc(1100px - 56px);
    }
}

/* 高さが 1080px 以下であれば */
/*
@media (max-height: 1080px){
    .app {
        grid-template-rows: 64px calc(1080px - 64px);
    }
}
*/

.app__header {
    position: sticky;
    top: 0px;

    grid-row: 1;
    grid-column: 1 / 3;
    background-color: #333941;
}

.app__sidenav {
    position: fixed;
    top: 64px;
    left: 0px;
    height: calc(100vh - 64px);

    grid-row: 2;
    grid-column: 1;
    background-color: #EFF0F2;
}

.app__body {
    grid-row: 2;
    grid-column: 2;
    overflow-y: auto;
    background-color: #EFF0F2;
}